.ant-slider {
  &.ant-slider-disabled {
    opacity: 0.7;
    .ant-slider-track {
      background-color: var(--zirozen-red) !important;
    }
    .ant-slider-rail {
      background-color: var(--zirozen-red) !important;
    }
    .ant-slider-handle {
      &::after {
        background-color: var(--zirozen-red) !important;
        box-shadow: 0 0 0 2px var(--zirozen-red) !important;
      }
    }
  }
  .ant-slider-track {
    background-color: var(--zirozen-red) !important;
  }
  .ant-slider-rail {
    background-color: var(--zirozen-red) !important;
  }
  .ant-slider-handle {
    &::after {
      background-color: var(--zirozen-red) !important;
      box-shadow: 0 0 0 2px var(--zirozen-red) !important;
    }
    &:hover {
      &::after {
        box-shadow: 0 0 0 2px var(--zirozen-red) !important;
      }
    }
  }
}