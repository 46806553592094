@tailwind components;
@tailwind utilities;

@ant-prefix: 'ant';

@import './vendor.less';
@import './nunito.less';
@import './nav.less';
@import './settings.less';
@import './animation.less';
@import './table.less';
@import './inventory.less';
@import './dashboard.less';
@import './variable.less';
@import './notification.less';
@import './tabs.less';
@import './login.less';
@import './editor.less';
@import './popover.less';
@import './utils.less';
@import './severity.less';
@import './drawer.less';
@import './buttons.less';
@import './progress.less';
@import './slider.less';
@import './input.less';
@import './general.less';

body {
  background-color: var(--page-background-color);
}

#root {
  @apply flex flex-col min-h-0 h-full overflow-auto;
  background-color: var(--page-background-color);
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: hidden;
  overflow: hidden;
}
