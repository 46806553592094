.react-grid-item.react-grid-placeholder {
  @apply bg-primary;
}

.widget-container {
  @apply shadow-sm rounded flex flex-col h-full;

  background: var(--seperator-background-color);

  .react-resizable-handle {
    &::after {
      @apply border-border;
    }
  }
}

.invisible-drawer {
  display: none;
  .ant-drawer-content {
    display: none;
  }
}

.dashboard-selector {
  border: 2px solid var(--primary);
  border-radius: 4px;
  .ant-popover-content {
    width: 40vw;
    height: 50vh;
    border-radius: 4px;
  }
  .ant-popover-inner {
    height: 100%;
    background-color: var(--page-background-color);
    border-radius: 4px;

    &-content {
      height: 100%;
      border-radius: 4px;

       > div {
        display: flex;
        flex-direction: column;
        height: 100%;
       }
    }
  }
}

.dashboard-list-item {
  &.active{
    border-left: 4px solid var(--primary);
  }
}