:not(.ant-drawer-bottom) .ant-drawer-content-wrapper {
  // &::after {
  //   position: absolute;
  //   content: ' ';
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   width: 4px;
  //   background: var(--primary);
  //   // background: linear-gradient(to top, var(--primary-lightest) 0%, var(--primary-lighter) 32%, var(--primary-light) 48%, var(--primary-dark) 64%, var(--primary-alt) 80%, var(--primary) 100%);
  // }
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  &::after {
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background: linear-gradient(to right, rgba(170,69,89,1) 0%, rgba(53,132,220,1) 100%);;
  }
}

.ant-drawer-body {
  background-color: var(--page-background-color);
}

.ant-drawer-right .ant-drawer-content-wrapper {
  height: 96%;
  margin-top: 1%;
  border-width: 5px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  .ant-drawer-content {
    position: relative;
    z-index: 1;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 4px;
      padding-right: 0;
      background: linear-gradient(to right, rgba(170,69,89,1) 0%, rgba(53,132,220,1) 100%);
      -webkit-mask:
         linear-gradient(#fff 0 0) content-box,
         linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude;
    }

    .ant-drawer-header {
      position: relative;
      z-index: 1;
    }
  }
}

.border-container {
  position: relative;
  &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 12px;
      padding: 1px;
      background: linear-gradient(to right, rgba(170,69,89,1) 0%, rgba(53,132,220,1) 100%);
      -webkit-mask:
         linear-gradient(#fff 0 0) content-box,
         linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude;
  }

  > * {
    position: relative;
    z-index: 2;
  }
}

.ant-drawer-title {
  width: 100%;
}