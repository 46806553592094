.inventory-tree {
  .ant-tree-treenode {
    // width: 100%;

    .ant-tree-node-content-wrapper {
      // width: 100%;
      line-height: 35px;
    }

    .ant-tree-switcher {
      line-height: 35px;
    }
  }

  &.inventory-tree-no-switcher {
    .ant-tree-switcher {
      display: none;
    }
  }
}

.vital-status-box {
  position: relative;
  &::before {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0;
    width: 30%;
    content: ' ';

    @apply rounded-lg;
  }
  &.error {
    &::before {
      background-image: linear-gradient(
        90deg,
        rgba(240, 66, 72, 0.13) 0%,
        rgba(240, 66, 72, 0) 100%
      );
    }
  }
  &.success {
    &::before {
      background-image: linear-gradient(90deg, rgba(0, 237, 81, 0.13) 0%, rgba(0, 237, 81, 0) 100%);
    }
  }
  &.offline {
    &::before {
      background-image: linear-gradient(90deg, #7b8fa5 0%, rgba(0, 237, 81, 0) 100%);
    }

    path {
      fill: var(--neutral-regular) !important;
    }
  }
  &.unknown-status {
    &::before {
      background-image: linear-gradient(90deg, rgb(47 47 47 / 14%) 0%, rgb(6 6 6 / 0%) 100%);
    }
  }
}

.transparent {
  .ant-input {
    background: transparent;
  }

  .ant-btn {
    background: transparent;
  }

  .ant-table,
  .ant-table-placeholder {
    background: transparent !important;
  }

  .ant-table-placeholder {
    .ant-table-cell {
      border: none;
    }
  }
}

.drawer-mask-layer {
  position: absolute;
  inset: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: auto;
}
