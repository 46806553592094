@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";
@import "/node_modules/cytoscape-panzoom/cytoscape.js-panzoom.css";
@import "/node_modules/cytoscape-panzoom/font-awesome-4.0.3/css/font-awesome.css";
@import '/node_modules/react-querybuilder/dist/query-builder.css';


.queryBuilder {
  // .ant-input {
  //   width: auto;
  // }
  .ruleGroup {
    border-color: transparent;
  }
}
.cy-panzoom {
  right: 55px;
  top: 10px;
}
