.text-color {
  color: var(--page-text-color);
}
body {
  color: var(--page-text-color);
}

.bg-lightest {
  background-color: var(--table-header-color);
}

.bg-seperator {
  background-color: var(--seperator-background-color);
}

.bg-table-header {
  background: var(--table-header-color);
}

.text-neutral-light {
  color: var(--neutral-light);
}

.gradient-text {
  background: var(
    --Rectangle,
    linear-gradient(
      91deg,
      #c15a32 14.93%,
      #aa4559 37.65%,
      #873599 61.44%,
      rgba(53, 132, 220, 0.4) 83.1%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  backdrop-filter: blur(16px);
  padding-bottom: 7px;
}

.risk-score-circle {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;

  &.risk-low {
    color: var(--secondary-green);
    border-color: var(--secondary-green);
  }
  &.risk-medium {
    color: var(--secondary-yellow);
    border-color: var(--secondary-yellow);
  }
  &.risk-high {
    color: var(--secondary-orange);
    border-color: var(--secondary-orange);
  }
  &.risk-critical {
    color: var(--secondary-red);
    border-color: var(--secondary-red);
  }
}

.vulnerability-score-card {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  border-radius: 8px;
  border: transparent solid 2px;
  font-weight: 500;

  &.full-circle {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    padding: 0;

    &.border-container {
      width: 52px;
      height: 52px;

      &::before {
        border-radius: 50%;
      }
    }

    &.small {
      height: 45px;
      width: 45px;
      font-size: 0.65rem;

      &.border-container {
        width: 47px;
        height: 47px;
      }
    }
  }

  &.vulnerability-critical {
    border-color: var(--secondary-red);
  }
  &.vulnerability-high {
    border-color: var(--secondary-orange);
  }
  &.vulnerability-medium {
    border-color: var(--secondary-yellow);
  }
  &.vulnerability-low {
    border-color: var(--secondary-green);
  }
}

.bg-zirozen-red {
  background-color: var(--zirozen-red);
  color: white;
}

.ant-segmented-item-selected {
  background: var(--default-gradient-color) !important;
  color: white !important;
}

.ant-switch-checked {
  background: var(--default-gradient-color) !important;
  color: white !important;
}

.border-container {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 2px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: linear-gradient(to right, rgba(170, 69, 89, 1) 0%, rgba(53, 132, 220, 1) 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  > * {
    position: relative;
    z-index: 2;
  }
}
