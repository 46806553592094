.generateSeverity(@color, @lighter, @lightest, @darkest, @borderWidth) {
  &.severity-dot-box {
    border: @borderWidth solid @color;

    .severity-dot {
      background: @lightest;
    }
  }

  &.shadow {
    background: @lightest;
  }

  &:not(.severity-dot-shadow)&:not(.text)&:not(.bg)&:not(.hovered) {
    color: white;
    background: @color;
  }

  &.hovered {
    &:hover {
      color: white;
      background: @color;
    }
  }

  &.text {
    color: @color;
  }
}

.neutral-severity {
  .generateSeverity(var(--primary-alt), fade(#3279be, 20), fade(#3279be, 35), darken(#3279be, 20), 2px);
}
.all {
  .generateSeverity(var(--primary-alt), fade(#3279be, 20), fade(#3279be, 35), darken(#3279be, 20), 2px);
}

.down {
  .generateSeverity(
    var(--severity-down),
    var(--severity-down-lighter),
    var(--severity-down),
    var(--severity-down-darker),
    0px
  );
}

.unreachable {
  .generateSeverity(
    var(--severity-unreachable),
    var(--severity-unreachable-lighter),
    fade(#8d3abc, 35),
    var(--severity-unreachable-darker),
    2px
  );
}

.critical {
  .generateSeverity(
    var(--severity-critical),
    var(--severity-critical-lighter),
    fade(#f04e3e, 35),
    var(--severity-critical-darker),
    2px
  );
}

.high {
  .generateSeverity(
    var(--severity-major),
    var(--severity-major-lighter),
    fade(#f58518, 35),
    var(--severity-major-darker),
    2px
  );
}

.medium, .warning {
  .generateSeverity(
    var(--severity-warning),
    var(--severity-warning-lighter),
    fade(#f5bc18, 35),
    var(--severity-warning-darker),
    2px
  );
}

.low {
  .generateSeverity(
    var(--severity-clear),
    var(--severity-clear-lighter),
    fade(#89c540, 35),
    var(--severity-clear-darker),
    2px
  );
}

.up {
  .generateSeverity(
    var(--severity-clear),
    var(--severity-clear-lighter),
    fade(#89c540, 35),
    var(--severity-clear-darker),
    2px
  );
}

.unknown {
  .generateSeverity(
    var(--severity-unknown),
    var(--severity-unknown-lighter),
    fade(#D3D3D3, 35),
    var(--severity-unknown-darker),
    2px
  );
}

.suspended {
  .generateSeverity(
    var(--severity-suspended),
    var(--severity-suspended-lighter),
    fade(#555555, 35),
    var(--severity-suspended-darker),
    2px
  );
}

.disable {
  .generateSeverity(
    var(--severity-disable),
    var(--severity-disable-lighter),
    var(--severity-disable),
    var(--severity-disable-darker),
    0px
  );
}

.none {
  .generateSeverity(
    var(--severity-none),
    var(--severity-none-lighter),
    fade(#7b8fa5, 35),
    var(--severity-none-darker),
    2px
  );
}

.maintenance {
  .generateSeverity(
    var(--severity-maintenance),
    var(--severity-maintenance-lighter),
    fade(#099dd9, 35),
    var(--severity-maintenance-darker),
    2px
  );
}

.stop {
  .generateSeverity(
    var(--severity-stop),
    var(--severity-stop-lighter),
    fade(#8d3abc, 35),
    var(--severity-stop-darker),
    2px
  );
}

.generateSeverityBg(@bg, @border) {
  &:not(.hovered) {
    background: @bg;
  }

  &.hovered {
    &:hover {
      background: @bg;
    }
  }

  &.border {
    border: 1px solid @border;
  }
}

.bg {
  &.up {
    .generateSeverityBg(var(--severity-clear-lighter), var(--severity-clear));
  }

  &.down {
    .generateSeverityBg(var(--severity-down-lighter), var(--severity-down));
  }

  &.unreachable {
    .generateSeverityBg(
      var(--severity-unreachable-lighter),
      var(--severity-unreachable)
    );
  }

  &.critical {
    .generateSeverityBg(
      var(--severity-critical-lighter),
      var(--severity-critical)
    );
  }

  &.high {
    .generateSeverityBg(var(--severity-major-lighter), var(--severity-major));
  }

  &.warning, &.medium {
    .generateSeverityBg(
      var(--severity-warning-lighter),
      var(--severity-warning)
    );
  }

  &.clear, &.low {
    .generateSeverityBg(var(--severity-clear-lighter), var(--severity-clear));
  }

  &.maintenance {
    .generateSeverityBg(
      var(--severity-maintenance-lighter),
      var(--severity-maintenance)
    );
  }

  &.unknown {
    .generateSeverityBg(
      var(--severity-unknown-lighter),
      var(--severity-unknown)
    );
  }

  &.suspended {
    .generateSeverityBg(
      var(--severity-suspended-lighter),
      var(--severity-suspended)
    );
  }

  &.disable {
    .generateSeverityBg(
      var(--severity-disable-lighter),
      var(--severity-disable)
    );
  }

  &.none {
    .generateSeverityBg(var(--severity-none-lighter), var(--severity-none));
  }

  &.stop {
    .generateSeverityBg(var(--severity-stop-lighter), var(--severity-stop));
  }
}

.severity-dot-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .severity-dot-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 11px;
    height: 11px;
    // padding: 2px;
    border-radius: 50%;

    // &.severity-dot-shadow {
    //   width: 20px;
    //   height: 20px;
    //   padding: 4px;
    // }
  }

  .severity-dot {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &.not-center {
    justify-content: flex-start;
  }
}
