.ant-btn.ant-btn-primary:not(.ant-btn-background-ghost) {
  color: var(--btn-text-color) !important;
  border-color: var(--btn-text-color) !important;
  background-color: transparent !important;

  &.gradient-btn {
    border: 1px solid var(--Radial, rgba(255,255,255,0.4)) !important;
    background: var(--default-gradient-color) !important;
    background-color: var(--primary) !important;
    color: white !important;
  }
}
.ant-btn-background-ghost {
  color: var(--btn-text-color) !important;
  border-color: var(--btn-text-color) !important;
}