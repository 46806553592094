.login-container{
  position: relative;
  .login-card-container {
    // background: radial-gradient(circle at center, #099dd9,#1f2937,#14212d 100%);
  }

  .login-tag-line {
    background: linear-gradient(to right, rgba(220,168,178,1) 0%, rgba(69,126,196,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 7px;
  }

  .login-card {
    background: linear-gradient(180deg, rgba(247, 249, 255, 1) 0%, rgba(247, 249, 255, 0.2) 100%);;
    // backdrop-filter: blur( 7.5px );
    border-radius: 20px;
    // box-shadow: 0 10px 15px -3px rgba(170, 69, 89, 0.4), 0 4px 6px -4px rgba(170, 69, 89, 0.4);
    // border: 1px solid #D9C2CA;

    @apply px-4 py-12;

    label {
      color: rgba(209,213, 219, 0.8);
      @apply text-base;
    }

    .ant-form-item-control-input-content,input,.ant-input-affix-wrapper, .ant-input-password-icon {
      // color: white;
      background-color: #E1EBF8;
      border-color: #B7C8DB;

      input {
        background-color: transparent;
      }

      ::placeholder{
        color: #CAD1D8;
      }

      @apply rounded-lg;
    }
  }
  .border-container {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 20px;
        padding: 1px;
        background: linear-gradient(to bottom, rgba(220,168,178,0.1) 0%, rgba(220,168,178,1) 100%);
        -webkit-mask:
           linear-gradient(#fff 0 0) content-box,
           linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
    }

    > * {
      position: relative;
      z-index: 2;
    }
  }
}