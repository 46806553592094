:root {
  --nav-text-color: #7B8FA5;
}

.ant-layout-sider {
  position: fixed !important;
  z-index: 99;
  height: 100%;
  background-color: var(--seperator-background-color) !important;

  & + .ant-layout {
    padding-left: 80px;
  }
}

.ant-menu-item:not(.ant-menu-item-selected), .ant-menu-submenu-title {
  color: var(--nav-text-color) !important;

  .anticon {
    color: var(--nav-text-color) !important;
  }
}

.ant-menu-item.ant-menu-item-selected {
  .anticon {
    position: relative;
    top: 5px;
    right: 3px;
  }
}

.settings-menu .ant-menu-item.ant-menu-item-selected {
  .anticon {
    position: relative;
    top: 0;
    right: 0;
  }
}

.ant-layout-sider-children {
  img {
    transition: width linear 0.2s;
    will-change: width;
  }
  .ant-menu.ant-menu-root.ant-menu-inline {
    border-inline-end: none;
  }

  .ant-menu {
    border: none !important;
    background-color: var(--seperator-background-color) !important;
  }
}

.count-badge {
  .ant-badge-count {
  background: var(--default-gradient-color);
    color: white;
  }
}