
.picker-overlay {
  .ant-popover-inner,
  .ant-tooltip-inner {
    display: flex;
    max-height: 35vh;
    background: var(--dropdown-background);

    > div {
      flex: 1;
      min-width: 0;
      min-height: 0;
    }
  }
}