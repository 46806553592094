:root {
  --zirozen-red: #AA4559;
  --zirozen-blue: #3584DC;
  --primary: #3584DC;
  --primary-alt: #3279be;
  --neutral-darker: #14212d;
  --neutral-dark: #364658;
  --neutral-regular: #7b8fa5;
  --neutral-light: rgba(0,0,0,0.5);
  --neutral-darkest: #000;
  --neutral-lightest: #eef2f6;
  --neutral-lighter: #dee5ed;
  --secondary-green: #89c540;
  --secondary-yellow: #f5bc18;
  --secondary-orange: #f58518;
  --secondary-red: #f04e3e;
  --secondary-red-dark: #c84235;
  --page-background-color: #fff;
  --border-color: var(--neutral-lighter);
  --default-gradient-color: linear-gradient(86deg, #AA4559 -90.28%, rgba(53, 132, 220, 0.40) 186.81%);

  --input-placeholder-color: fade(#364658, 50);

  // Severity Color
  --severity-down-lighter: fade(#e72b25, 20);
  --severity-down-lightest: fade(#e72b25, 20);
  --severity-down: #e72b25;
  --severity-down-darker: darken(#e72b25, 20);
  --severity-critical-lighter: fade(#f04e3e, 20);
  --severity-critical-lightest: fade(#f04e3e, 20);
  --severity-critical: #f04e3e;
  --severity-critical-darker: darken(#f04e3e, 20);
  --severity-major-lighter: fade(#f58518, 20);
  --severity-major-lightest: fade(#f58518, 20);
  --severity-major: #f58518;
  --severity-high: #f58518;
  --severity-major-darker: darken(#f58518, 20);
  --severity-warning-lighter: fade(#f5bc18, 20);
  --severity-warning-lightest: fade(#f5bc18, 20);
  --severity-warning: #f5bc18;
  --severity-medium: #f5bc18;
  --severity-warning-darker: darken(#f5bc18, 20);
  --severity-clear-lighter: fade(#89c540, 20);
  --severity-clear-lightest: fade(#89c540, 20);
  --severity-clear: #89c540;
  --severity-low: #89c540;
  --severity-clear-darker: darken(#89c540, 20);
  --severity-up-lighter: fade(#89c540, 20);
  --severity-up-lightest: fade(#89c540, 20);
  --severity-up: #89c540;
  --severity-up-darker: darken(#89c540, 20);
  --severity-maintenance-lighter: fade(#099dd9, 20);
  --severity-maintenance-lightest: fade(#099dd9, 20);
  --severity-maintenance: #099dd9;
  --severity-maintenance-darker: darken(#099dd9, 20);
  --severity-stop-lighter: fade(#8d3abc, 20);
  --severity-stop-lightest: fade(#8d3abc, 20);
  --severity-stop: #8d3abc;
  --severity-stop-darker: darken(#8d3abc, 20);
  --severity-unreachable-lighter: fade(#8d3abc, 20);
  --severity-unreachable-lightest: fade(#8d3abc, 20);
  --severity-unreachable: #8d3abc;
  --severity-unreachable-darker: darken(#8d3abc, 20);
  --severity-disable-lighter: fade(#808080, 20);
  --severity-disable-lightest: fade(#808080, 20);
  --severity-disable: #808080;
  --severity-disable-darker: darken(#808080, 20);
  --severity-unknown-lighter: fade(#d3d3d3, 20);
  --severity-unknown-lightest: fade(#d3d3d3, 20);
  --severity-unknown: #d3d3d3;
  --severity-unknown-darker: darken(#d3d3d3, 20);
  --severity-suspended-lighter: fade(#555, 20);
  --severity-suspended-lightest: fade(#555, 20);
  --severity-suspended: #555;
  --severity-suspended-darker: darken(#555, 20);
  --severity-none-lighter: fade(#7b8fa5, 20);
  --severity-none-lightest: fade(#7b8fa5, 20);
  --severity-none: #7b8fa5;
  --severity-none-darker: darken(#7b8fa5, 20);

  // chart related variables
  --chart-tooltip-background: rgba(255, 255, 255, 0.8);
  --chart-legend-color: rgba(54, 70, 88, 0.8);
  --chart-legend-color: rgba(54, 70, 88, 0.8);
  --chart-font-family: 'Nunito', sans-serif;
  --page-text-color: #364658;

  // dropdown
  --dropdown-background: var(--page-background-color);
  --dropdown-text: var(--neutral-regular);
  --hieararchy-border-color: var(--border-color);

  --default-tag-color: #eef2f6;
  --seperator-background-color: #f2f2f2;
  --table-header-color: #fafafa;
  --btn-text-color: rgba(0,0,0,0.5);

  --gauge-trail-color: rgba(0, 0, 0, 0.06);
}

[data-theme='dark-theme'] {
  --primary: #099dd9;
  --primary-alt: #3279be;
  --neutral-darker: #14212d;
  --neutral-dark: #1e1e30;
  --neutral-regular: #7b8fa5;
  --neutral-light: rgba(255,255,255,0.7);
  --neutral-darkest: #000;
  --neutral-lightest: var(--neutral-dark);
  --neutral-lighter: #22223c;
  --page-background-color: rgb(22, 22, 22);
  --border-color: rgb(60, 60, 60);
  --page-text-color: #fff;

  --default-tag-color: var(--neutral-lighter);
  --dropdown-background: var(--page-background-color);
  --chart-tooltip-background: rgba(90, 90, 90, 0.8);

  --seperator-background-color: #242424;

  --btn-text-color: rgba(255,255,255,0.5);

  --table-header-color: #242424;

  --gauge-trail-color: rgba(255, 255, 255, 0.06);
}