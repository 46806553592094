.bordered {
  border: 1px solid var(--border-color);
}

.bordered-x {
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
}

.bordered-y {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.border-right {
  border-right: 1px solid var(--border-color);
}

.border-left {
  border-left: 1px solid var(--border-color);
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .ant-tag {
    margin-bottom: 8px;
  }
}

.popover-content {
  max-height: 200px; /* Set the max height of the popover */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px;

  .popover-tags {
    display: flex;
    flex-direction: column; /* Stack tags vertically */
    gap: 8px;
  }

  .search-input {
    margin-bottom: 10px; /* Add space between the search input and the tag list */
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 2s linear infinite;
}
.ai-reponse-viewer {
  pre, code {
    min-height: fit-content;
  }
}