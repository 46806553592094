.ant-tabs {
  &-left, &-right {
    .ant-tabs-nav-operations {
      display: none !important;
    }
  }
  &.w-full {
    .ant-tabs-nav {
      width: 100%;
    }
  }
.ant-tabs-tab {
.anticon {
  margin-right: 5px;
}
}

&.device-automation-tab {
  .ant-tabs-tab {
    padding-left: 0;
    padding-right: 0;
    .anticon {
      margin-right: 10px;
    }
    }
}
}
.sticky-tabs {
  position: sticky;
  top: 0;
  background-color: var(--page-background-color);
  margin: 0 0 16px 0;
  z-index: 2;
  .ant-tabs-nav {
    position: sticky;
    margin: 0;
    top: 0;
    background-color: var(--page-background-color);
    z-index: 2;
  }

  &.transparent {
    background-color: transparent;
    .ant-tabs-nav {
      background-color: transparent;
    }
  }
  &.no-margin{
    margin: 0;
  }
  &.no-border {
    .ant-tabs-nav {
      &::before {
        display: none;
      }
    }
  }
}