.package-picker {
  .ant-select-selection-item {
    height: auto !important;
  }
}

input.no-border {
  border: none !important;
}

.ant-transfer .ant-transfer-list-content-item-text {
  overflow: unset;
}

.wide-addon-after {
  .ant-input-group-addon {
    width: 80%;
    text-align: left;
  }
}

.no-lebel-input {
  .ant-form-item-label {
    display: none;
  }
}

.no-bg-ckeckbox {
  .ant-checkbox .ant-checkbox-inner {
    background-color: transparent !important;
  }
}
