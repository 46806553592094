.ant-message {
  top: 0 !important;
  z-index: 1051;

  &-notice-content {
    padding: 0;
  }

  &-custom-content {
    color: white;
    border-radius: 4px;

    .anticon {
      color: white !important;
    }
  }

  &-notice-error {
    .ant-message-notice-content {
      background: var(--secondary-red) !important;
    }
  }

  &-notice-success {
    .ant-message-notice-content {
      background: var(--secondary-green) !important;
    }
  }

  &-notice-warning {
    .ant-message-notice-content {
      background: var(--secondary-orange) !important;
    }
  }

  &-notice-info,
  &-notice-loading {
    .ant-message-notice-content {
      background: var(--primary-alt) !important;
    }
  }
}

.rounded-counter {
  width: 50px;
  height: 50px;
  font-size: 25px;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  border: 2px solid var(--primary);
  border-radius: 50%;

  &.warning {
    border-color: var(--secondary-orange);
  }

  &.success {
    border-color: var(--secondary-green);
  }

  &.error {
    border-color: var(--secondary-red);
  }

  &.neutral-lighter {
    border-color: var(--neutral-lighter);
  }

  &.info {
    border-color: var(--secondary-orange);
  }

  &.fill {
    @apply bg-primary text-white;

    &.warning {
      background-color: var(--secondary-orange);
    }

    &.success {
      background-color: var(--secondary-green);
    }

    &.error {
      background-color: var(--secondary-red);
    }

    .info {
      background: var(--secondary-orange);
    }

    &.neutral-lighter {
      border-color: var(--border-color);
    }
  }
}
