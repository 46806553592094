.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin-inline: 0 -4px;
}

.info-table {
  width: 100%;
  font-size: 0.85rem;

  tr:nth-child(even) {
    // background-color: #F4F6F9;
  }

  tr {
    td:first-child {
      color: #7B8FA5;
    }
  }
}


.row-error {
  td:first-child {
    position: relative;
    &::before {
      content: ' ';
      position: absolute;
      height: 10px;
      width: 10px;
      background-color: var(--secondary-red-dark);
      top: calc(50% - 5px);
      border-radius: 50%;
      left: 0;
    }
    // border-left: 2px solid var(--secondary-red-dark);
    padding-left: 16px !important;
  }
}
.row-success {
  td:first-child {
    position: relative;
    &::before {
      content: ' ';
      position: absolute;
      height: 10px;
      width: 10px;
      background-color: var(--secondary-green);
      top: calc(50% - 5px);
      border-radius: 50%;
      left: 0;
    }
    // border-left: 2px solid var(--secondary-red-dark);
    padding-left: 16px !important;
  }
}
.row-vulnerability-error {
  td:first-child {
    position: relative;
    &::after {
      content: ' ';
      position: absolute;
      height: 10px;
      width: 10px;
      background-color: var(--secondary-red-dark);
      top: calc(50% - 5px);
      border-radius: 50%;
      left: 13px;
    }
    // border-left: 2px solid var(--secondary-red-dark);
    padding-left: 27px !important;
  }
}
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  &.dot-error {
    background-color: var(--secondary-red-dark);
  }
  &.dot-success {
    background-color: var(--secondary-green);
  }
}
.row-vulnerability-success {
  td:first-child {
    position: relative;
    &::after {
      content: ' ';
      position: absolute;
      height: 10px;
      width: 10px;
      background-color: var(--secondary-green);
      top: calc(50% - 5px);
      border-radius: 50%;
      left: 13px;
    }
    // border-left: 2px solid var(--secondary-red-dark);
    padding-left: 27px !important;
  }
}

.crud-table {
  &.ant-table-wrapper {
    height: 100%;

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
        display: flex;
        flex-direction: column;

        .ant-table {
          flex: 1;
          min-height: 0;

          .ant-table-container {
            height: 100%;
            display: flex;
            flex-direction: column;

            .ant-table-content {
              flex: 1;
              min-height: 0;
              overflow: auto;
            }
          }
        }
      }
    }
  }

  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 3;

    .ant-table-column-sorter {
      margin-inline-end: 4px;
    }

    .react-resizable-handle {
      position: absolute;
      right: 0px;
      bottom: 0;
      z-index: 1;
      width: 10px;
      height: 100%;
      cursor: col-resize;
    }
  }

  .ant-table-tbody {
    background: url(~/public/images/brand-logo.svg);
    background-repeat: repeat-y;
    background-size: contain;
    background-position: center;
  }
}

.ant-table-wrapper {
  .ant-table-tbody>tr>td {
    padding: 8px;
  }

  tr.ant-table-expanded-row > td {
    background: var(--page-background-color);
  }

  col.ant-table-expand-icon-col {
    width: 30px;
  }

  .ant-table-thead >tr>th, .ant-table-thead >tr>td {
    background: var(--table-header-color);
  }
}
