.settings-menu {
  .ant-menu-sub {
    background: transparent !important;
  }
  .ant-menu-item:not(.ant-menu-item-only-child), .ant-menu-submenu-title {
    color: var(--nav-text-color);
  }
  .ant-menu-item:not(.ant-menu-item-only-child) {
    .anticon {
      color: var(--nav-text-color);
    }
  }
  .ant-menu-submenu-title, .ant-menu-item:not(.ant-menu-item-only-child) {
    padding-left: 0 !important;
  }
  .ant-menu-item.ant-menu-item-only-child {
    padding-left: 24px !important;
  }
}

.ant-checkbox-wrapper-disabled {
  pointer-events: none;
}

.tree-transfer {
  .ant-transfer-list-body {
    overflow: auto;
  }
}

.agent-download-modal {
  .ant-modal-header {
    text-align: center;
  }
}
.ant-modal-mask {
  backdrop-filter: blur(5px);
}
.ant-modal-content {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 1px;
    background: linear-gradient(to right, rgba(170,69,89,1) 0%, rgba(53,132,220,1) 100%);
    -webkit-mask:
       linear-gradient(#fff 0 0) content-box,
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }

  .ant-modal-body {
    position: relative;
    z-index: 2;
  }
}

// stylelint-disable

* {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 10px;
    scrollbar-color: #7f7f7f white;
    scrollbar-width: auto;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53.3%, 0.4);
    }
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

* {
  scrollbar-width: auto;
}
